import { Link, useNavigate, useParams } from "react-router-dom"
import KlatscherSlide from "../assets/img/slide/klatscher_slider.webp"
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, Autoplay, Navigation } from 'swiper/modules';
import "swiper/css";
import { ConvertDate, Countdown, Language, Root, Seo } from "./element";
import { useEffect, useState } from "react";
import Events from "../data/events.json"
import SlideEvents from "../data/slide_events.json"

import axios from "axios";
import Button from "../data/button.json"
import AlertData from "../data/alert.json"
import FooterData from "../data/footer.json"
import MenuData from "../data/menu.json"
import Logo from "../assets/img/LogoColor.png"

const Header = () => {

    const navigate = useNavigate()
    const SelectLanguage = (e) => {
        window.localStorage.removeItem("lang")
        window.localStorage.setItem("lang", e)
        window.location.href = window.location.pathname;
    }
    const [Show, setShow] = useState(0)
    return (
        <>
            <div className={`is-header`}></div>
            <div className={`header shadow-sm is-sticky`}>
                <div className="container">
                    <div className="header-container">
                        <div className="header-logo">
                            <Link to={"/"}>
                                <img src={Logo} className="img-fluid" />
                            </Link>
                        </div>
                        <div className="header-menu is-desktop">
                            {
                                (MenuData || []).filter((f) => { return (f["lang"] === Language() && f["section"] === "menu") }).map((d) => {
                                    return (
                                        (d["data"] || []).slice(0, 3).map((s, y) => {
                                            return (
                                                <Link className="header-menu-item" to={s["url"]}>{s["name"]}</Link>
                                            )
                                        })
                                    )
                                })
                            }
                        </div>
                        <div className="header-action is-desktop">
                            <button className="btn btn-action-item w-100 is-desktop" onClick={() => { navigate("/event") }}>{Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 1) })[0]["button"]}</button>
                            <button className="btn btn-action-item btn-outline" onClick={() => {
                                window.localStorage.getItem("lang") === null ?
                                    SelectLanguage("de")
                                    :
                                    window.localStorage.getItem("lang") === "tr" ? SelectLanguage("de") : SelectLanguage("tr")

                            }}>{window.localStorage.getItem("lang") === "tr" ? "DE" : "TR"}</button>
                        </div>
                        <div className="header-hammurger is-mobile">
                            <div className="d-flex">
                                <i class="fa-solid fa-bars fs-1 w-100 me-3" onClick={() => { setShow(1) }}></i>
                                <button className="btn btn-action-item btn-outline" style={{ height: "28px", width: "30px" }} onClick={() => {
                                    window.localStorage.getItem("lang") === null ?
                                        SelectLanguage("de")
                                        :
                                        window.localStorage.getItem("lang") === "tr" ? SelectLanguage("de") : SelectLanguage("tr")

                                }}>{window.localStorage.getItem("lang") === "tr" ? "DE" : "TR"}</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className={`modal-menu ${parseInt(Show) === 1 ? "active" : ""}`} onClick={() => { setShow(0) }}>
                <div className={`modal-menu-content shadow-sm ${parseInt(Show) === 1 ? "active" : ""}`}>
                    <div className="modal-menu-content-body">
                        <Link to={"/"}>
                            <img src="https://kompass.events/static/media/logo.1e7aa7b143207d709603.png" className="header-logo" />
                        </Link>
                        <div className="header-modal-menu-list">
                            {
                                (MenuData || []).filter((f) => { return (f["lang"] === Language() && f["section"] === "menu") }).map((d) => {
                                    return (
                                        (d["data"] || []).slice(0, 12).map((s, y) => {
                                            return (
                                                <Link className="header-modal-menu-item" to={s["url"]}>{s["name"]}</Link>
                                            )
                                        })
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const Footer = () => {


    return (
        <div className="footer">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-8 col-md-8 col-12">
                        <Newsletter />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-6 col-12 text-center">
                        <img src="https://kompass.events/static/media/footer_logo.0b89600ae436ca7dccb6.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="footer-title">KOMPASS EVENTS</div>
                        <div className="d-flex justify-content-center">
                            <div className="footer-divider"></div>
                        </div>
                        <div className="footer-text">Schaufelbergerstrasse 57, CH-8055 Zürich</div>
                        <div className="footer-text">+41 (0) 44 576 60 80</div>
                        <div className="footer-text">info@kompass.events</div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="footer-title">   {(FooterData || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["title"]}</div>
                        <div className="d-flex justify-content-center">
                            <div className="footer-divider"></div>
                        </div>
                        <div className="footer-text">  {(FooterData || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["subtitle"]}</div>
                        <div className="footer-social-content">
                            <div className="social-item" onClick={() => { window.open("https://www.facebook.com/profile.php?id=61557094736347", "blank") }}><i class="fa-brands fa-square-facebook"></i></div>
                            <div className="social-item" onClick={() => { window.open("https://www.instagram.com/kompassevents/", "blank") }}><i class="fa-brands fa-square-instagram"></i></div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="footer-title"> {(FooterData || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 3) })[0]["title"]}</div>
                        <div className="d-flex justify-content-center">
                            <div className="footer-divider"></div>
                        </div>
                        <div className="footer-link-content">
                            {
                                (MenuData || []).filter((f) => { return (f["lang"] === Language() && f["section"] === "menu") }).map((d) => {
                                    return (
                                        (d["data"] || []).slice(0, 8).map((s, y) => {
                                            return (
                                                <Link className="footer-link-item" to={s["url"]}>{s["name"]}</Link>
                                            )
                                        })
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-12">
                        <div className="footer-divider w-100"></div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-12">
                        <div className="footer-corporate">
                            © 2024 KOMPASS EVENTS.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const Section = ({ title = "", image = "", children, classname = "" }) => {

    const Head = ({ title = "" }) => {
        return (<div className="section-head">{title}</div>)
    }

    return (
        <div className={`section-container ${classname}`} style={{ backgroundImage: `url(${image})` }}>
            <div className="container">
                <Head title={title} />
                {children}
            </div>
        </div>
    )
}
const Card = ({ id = "", url = "", maps = "", image = "", title = "", address = "", calendar = "" }) => {
    const navigate = useNavigate()
    const [Open, setOpen] = useState({ open: "", title: "" })
    return (
        <>
            <div className="card rounded-0 shadow-sm margin-dektop">
                <img src={image} className="img-fluid" />
                <div className="card-body">
                    <div className="card-title">{id === "fazilsay-2024" ? <>
                        {
                            title.split("(")[0]
                        }
                        {Language() === "de" ? "(KLAVIERREZITAL)" : "(PİYANO RESİTALİ)"}

                    </> : title}   </div>
                    <div className="card-text">
                        <span className="w-20px w-md-20px"><i class="fa-solid fa-location-dot"></i></span>
                        {address}
                    </div>
                    <div className="card-text">
                        <span className="w-20px w-md-30px"><i class="fa-solid fa-calendar-days"></i></span>
                        {ConvertDate({ date: calendar.split(" - ")[0], type: "day" })}, {ConvertDate({ date: calendar.split(" - ")[0], type: "mount" }).day} {ConvertDate({ date: calendar.split(" - ")[0], type: "mount" }).mount} {ConvertDate({ date: calendar.split(" - ")[0], type: "mount" }).year} - {calendar.split(" - ")[1]}
                    </div>
                    <div className="btn-action">
                        <button className="btn btn-action-item w-100" onClick={() => { navigate(`/event/detail/${Seo(title)}/${id}`) }}>{Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 4) })[0]["button"]}</button>
                        {
                            new Date(calendar.split(" - ")[0]) > new Date() &&
                            <>
                                <button className="btn btn-action-item w-100" onClick={() => { window.open(url, "blank") }}>{Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 1) })[0]["button"]}</button>
                                <button className="btn btn-action-item w-100" onClick={() => { setOpen({ open: maps, address }) }}>{Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 7) })[0]["button"]}</button>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Highlight open={Open.open} title={Open.title} data={<iframe src={Open.open} width="100%" height="500px" style={{ border: "0" }} ></iframe>} response={() => { setOpen("") }} />
        </>
    )
}
const Slide = () => {
    const navigate = useNavigate()
    const [Open, setOpen] = useState({ open: "", title: "" })
    return (
        <>
            <Swiper modules={[Autoplay]} spaceBetween={2} slidesPerView={1} loop={true} autoplay={{ delay: 4000 }} >
                {
                    /**new Date() <= new Date(d["date"]) && */
                    (SlideEvents || []).filter((d) => { return (d["lang"] === Language() && new Date() <= new Date(d["date"]) && d["slide"] === true) }).map((d, x) => {
                        return (
                            <SwiperSlide>
                                <div className="slide" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/new/events/${d["image"]["slide_image"]})` }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-5 col-12">
                                                <div className="slide-title">{d["title"]} </div>
                                                <div className="slide-subtitle">
                                                    {d["id"] === "fazilsay-2024" ? Language() === "de" ? "KLAVIERREZITAL" : "PİYANO RESİTALİ" : d["subtitle"]}
                                                </div>
                                                <div className="slide-text">
                                                    <span className="w-30px w-md-20px"><i class="fa-solid fa-location-dot"></i></span>
                                                    {d["adress"]}
                                                </div>
                                                <div className="slide-text">
                                                    <span className="w-30px w-md-20px"><i class="fa-solid fa-calendar-days"></i></span>
                                                    {ConvertDate({ date: d["date"], type: "day" })}, {ConvertDate({ date: d["date"], type: "mount" }).day} {ConvertDate({ date: d["date"], type: "mount" }).mount} {ConvertDate({ date: d["date"], type: "mount" }).year} - {d["time"]}
                                                </div>
                                                <div className="btn-action slide-action">
                                                    <button className="btn btn-action-item w-100 border" onClick={() => { navigate(`/event/detail/${Seo(d["time"])}/${d["id"]}`) }}>
                                                        {Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 4) })[0]["button"]}
                                                    </button>
                                                    <button className="btn btn-action-item w-100 border" onClick={() => { window.open(d["ticket"], "target") }}>
                                                        {Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 1) })[0]["button"]}
                                                    </button>
                                                    <a hidden className="btn btn-action-item w-100 border" target={"_blank"} href="https://pusulaswiss.ch/api/upload/other/bu%CC%88roschu%CC%88r_8%20seitig.pdf">
                                                        {/**onClick={() => { setOpen({ open: d["maps"], title: d["adress"] }) }} */}
                                                        {Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["button"]}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <Highlight open={Open.open} title={Open.title} data={<iframe src={Open.open} width="100%" height="500px" style={{ border: "0" }} ></iframe>} response={() => { setOpen("") }} />
        </>
    )
}
const Banner = ({ date = "", image = "", url = "", maps = "", title = "", subtitle = "", address = "", calendar = "", response = () => { } }) => {
    const { id } = useParams()
    const [Open, setOpen] = useState({ open: "", title: "" })

    console.log(id)
    return (
        <>
            <div className="slide" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/new/events/${image})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-12">
                            <div className="slide-title">{title} </div>
                            <div className="slide-subtitle"> {subtitle}</div>

                            {new Date(date) >= new Date() && <Countdown endDate={date} />}

                            <div className="slide-text">
                                <span className="w-30px w-md-20px"><i class="fa-solid fa-calendar-days"></i></span>
                                {ConvertDate({ date: calendar.split(" - ")[0], type: "day" })}, {ConvertDate({ date: calendar.split(" - ")[0], type: "mount" }).day} {ConvertDate({ date: calendar.split(" - ")[0], type: "mount" }).mount} {ConvertDate({ date: calendar.split(" - ")[0], type: "mount" }).year} - {calendar.split(" - ")[1]}
                            </div>
                            {
                                new Date(date) >= new Date() &&
                                <div className="btn-action slide-action">
                                    <button className="btn btn-action-item border" style={{ width: "240px" }} onClick={() => { window.open(url, "blank") }}>
                                        {Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 1) })[0]["button"]}
                                    </button>
                                    <a hidden className="btn btn-action-item border" style={{ width: "240px" }} target="_blank" href="https://pusulaswiss.ch/api/upload/other/bu%CC%88roschu%CC%88r_8%20seitig.pdf">
                                        {id === "fazilsay-2024" ? Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["button"] : Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 3) })[0]["button"]}
                                    </a>
                                    <button className="btn btn-action-item border" style={{ width: "240px" }} onClick={() => { setOpen({ open: maps, title: address }) }}>
                                        {Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 7) })[0]["button"]}
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Highlight open={Open.open} title={Open.title} data={<iframe src={Open.open} width="100%" height="500px" style={{ border: "0" }} ></iframe>} response={() => { setOpen("") }} />
        </>
    )
}
const Newsletter = () => {
    const [Email, setEmail] = useState("")
    const [Alert, setAlert] = useState({ status: 0, text: "" })
    const Control = () => {
        if (Email === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 3) })[0]["data"][0]["name"] }); return false; }
        return true;
    }
    const Company = () => {
        const content = "" +
            "<br/>" +
            "Guten Tag" +
            "<br/>" +
            "Ihr Abonnement wurde erfolgreich abgeschlossen! Um über unsere Veranstaltungen informiert zu bleiben, überprüfen Sie bitte Ihren E-Mail-Posteingang." +
            "<br/>" +
            "Vielen Dank, dass Sie uns folgen." +
            "<br/><br/>" +
            "Kompass Events<br/>" +
            "Schaufelbergerstrasse 57 <br/>" +
            "CH-8055 Zürich<br/><br/>" +
            "info@kompass.events<br/>" +
            "+41 (0) 44 576 60 80<br/>" +
            "";

        axios.post(Root({ type: "root" }), {
            params: "send-contact",
            email: Email,
            name: "Kompass Events",
            title: "Registrierungsbestätigung",
            content: content,
        })

        axios.post(Root({ type: "root" }), {
            params: "insert-newsletter",
            email: Email,
            content: content,
        })



        return true;

    }
    const Send = () => {
        if (Control()) {
            if (Company()) {
                setAlert({ status: 1, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 4) })[0]["data"][0]["name"] })
            }
        }
    }

    useEffect(() => {
        if (Alert.status) {
            setTimeout(() => {
                setAlert({ status: 0, text: "" })
            }, 3000);
        }
    }, [Alert.status])

    return (
        <>

            <div className="footer-newsletter-container">
                <div className="footer-newsletter-title"> {(FooterData || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 1) })[0]["title"]}</div>
                <div className="alert-danger" hidden={Alert.status === 2 ? false : true}>
                    {Alert.text}
                </div>
                <div className="alert-success" hidden={Alert.status === 1 ? false : true}>
                    {Alert.text}
                </div>
                <div className="footer-newsletter">
                    <input className="form-control input-action h-60px" value={Email} onChange={(e) => { setEmail(e.target.value) }} placeholder={(FooterData || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 1) })[0]["placeholder"]} />
                    <button className="btn btn-action-item h-60px w-150px" onClick={() => { Send() }}>{Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 6) })[0]["button"]}</button>
                </div>
            </div>
        </>
    )
}
const Image = ({ image = "", corporate = "" }) => {

    const [Active, setActive] = useState("")
    return (
        <>
            <div className="event-galery-image shadow-sm" style={{ backgroundImage: `url(${image})` }}>
                <i className="fa-solid fa-magnifying-glass-plus fs-1" onClick={() => { setActive(image) }}></i>

            </div>
            {
                corporate !== "" &&
                <div className="event-galery-corporate">
                    Fazıl Say © {corporate}
                </div>
            }


            <div className={`hightlight-container ${Active !== "" ? "active" : ""}`} onClick={() => { setActive("") }}>
                <div className="hightlight-inner">
                    <i class="fa-solid fa-circle-xmark" onClick={() => { setActive("") }}></i>
                    <div style={{ backgroundImage: `url(${Active})` }} className="hightlight-image"></div>
                </div>
            </div>
        </>
    )
}
const Video = ({ video = "" }) => {
    return (<iframe width="100%" height="310" style={{ borderRadius: "12px" }} frameborder="0" src={`https://www.youtube.com/embed/${video}`}></iframe>)
}
const Highlight = ({ open = "", data = <></>, title = "", response = () => { } }) => {

    return (
        <div className={`highlight-container ${open ? "active" : ""}`} onClick={() => { response() }}>
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header d-flex">
                                <div className="w-100 d-flex align-items-center">
                                    {title}
                                </div>
                                <div className="w-50px d-flex justify-content-end">
                                    <i class="fa-solid fa-circle-xmark fs-1 text-gray cursor-pointer" onClick={() => { response() }}></i>
                                </div>
                            </div>
                            <div className="card-body">
                                {data}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function Module({
    module = "",
    children,
    section = { title: "", image: "", classname: "" },
    banner = { date: "", image: "", url: "", maps: "", title: "", subtitle: "", address: "", calendar: "", response: () => { } },
    card = { id: "", url: "", maps: "", image: "", title: "", address: "", calendar: "" },
    image = { data: "", corporate: "" },
    video = { data: "" }
}) {

    switch (module) {
        case "section": return <Section title={section.title} image={section.image} children={children} classname={section.classname} />
        case "header": return <Header />
        case "footer": return <Footer />
        case "banner": return <Banner date={banner.date} image={banner.image} url={banner.url} maps={banner.maps} title={banner.title} subtitle={banner.subtitle} address={banner.address} calendar={banner.calendar} response={banner.response} />
        case "slide": return <Slide />
        case "image": return <Image image={image.data} corporate={image.corporate} />
        case "video": return <Video video={video.data} />
        case "card": return <Card id={card.id} url={card.url} maps={card.maps} image={card.image} title={card.title} address={card.address} calendar={card.calendar} />
        default: return <></>

    }
}