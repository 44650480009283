import { useParams } from "react-router-dom";
import Module from "../libs/module";
import Events from "../data/events.json"
import About from "../data/about.json"
import Section from "../data/section.json"
import AkretidationData from "../data/akretidation.json"
import ContactForm from "../data/contact.json"
import faqData from "../data/faq.json"
import Button from "../data/button.json"
import AlertData from "../data/alert.json"
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Language, Root } from "../libs/element";
import BannerData from "../data/banner.json"
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, Autoplay, Navigation } from 'swiper/modules';
import "swiper/css";


const Faq = () => {
    const [Active, setActive] = useState(1)
    return (
        <>
            <div className="banner" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/new/banner-inner2.jpg)` }}>
                <div className="container">
                    <div className="banner-container">
                        <div className="banner-title"> {BannerData.filter((d) => { return (d["id"] === 5 && d["lang"] === Language()) })[0]["title"]}</div>
                    </div>
                </div>
            </div>
            <Module module="section" section={{ title: "" }}>
                <div className='row d-flex justify-content-center'>
                    {
                        (faqData || []).filter((f) => { return (f["lang"] === Language()) }).map((d, x) => {
                            return (
                                <div className="col-lg-12 mb-2">
                                    <div className={`tabs-head ${Active === x ? "active" : ""}`} onClick={() => { setActive(x) }}>
                                        {d["title"]}
                                    </div>
                                    <div className={`tabs-content ${Active === x ? "active" : ""}`}>
                                        {d["text"]}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="faq-image" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/kompass_events/faq-background.png)` }}></div>
            </Module>


        </>
    )
}
const Home = () => {
    return (
        <>
            <Module module="slide" />

            {
                /*
                (Events || []).filter((f) => { return (new Date() <= new Date(f["date"]) && f["lang"] === Language()) }).length > 0 &&
                <Module module="section" section={{ title: Section.filter((d) => { return (d["id"] === 1 && d["lang"] === Language()) })[0]["title"] }} >
                    <div className='row d-flex justify-content-center'>
                        {
                            (Events || []).filter((f) => { return (new Date() <= new Date(f["date"]) && f["lang"] === Language()) }).map((d) => {
                                return (
                                    <div className='col-xl-4 col-lg-6 col-md-6 mb-4'>
                                        <Module
                                            module="card"
                                            card={{
                                                id: d["id"],
                                                url: d["ticket"],
                                                maps: d["maps"],
                                                image: `https://kompass.events/kompass_events/upload/new/events/${d["image"]["event_image"]}`,
                                                title: `${d["title"]} (${d["subtitle"]})`,
                                                address: `${d["adress"]}`,
                                                calendar: `${d["date"]} - ${d["time"]}`
                                            }}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Module>
                */
            }

            <Module module="section" section={{ title: Section.filter((d) => { return (d["id"] === 2 && d["lang"] === Language()) })[0]["title"], classname: "mobile-background" }}>
                <div className='row d-flex justify-content-center'>
                    {
                        (Events || []).sort((a, b) => { return (new Date(b["date"]) - new Date(a["date"])) }).filter((f) => { return (new Date() > new Date(f["date"]) && f["lang"] === Language()) }).map((d) => {
                            return (
                                <div className='col-xl-4 col-lg-6 col-md-6 mb-4'>
                                    <Module
                                        module="card"
                                        card={{
                                            id: d["id"],
                                            url: d["ticket"],
                                            maps: d["maps"],
                                            image: `https://kompass.events/kompass_events/upload/new/events/${d["image"]["event_image"]}`,
                                            title: `${d["title"]} (${d["subtitle"]})`,
                                            address: `${d["adress"]}`,
                                            calendar: `${d["date"]} - ${d["time"]}`
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </Module>
        </>
    )

}
const Event = () => {

    return (
        <>
            <div className="banner" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/new/banner-inner2.jpg)` }}>
                <div className="container">
                    <div className="banner-container">
                        <div className="banner-title">{BannerData.filter((d) => { return (d["id"] === 2 && d["lang"] === Language()) })[0]["title"]}</div>
                    </div>
                </div>
            </div>
            <Module module="section" section={{ title: Section.filter((d) => { return (d["id"] === 1 && d["lang"] === Language()) })[0]["title"] }} >
                <div className='row d-flex justify-content-center'>
                    {
                        (Events || []).filter((f) => { return (new Date() <= new Date(f["date"]) && f["lang"] === Language()) }).map((d) => {
                            return (
                                <div className='col-xl-4 col-lg-6 col-md-6 mb-4'>
                                    <Module
                                        module="card"
                                        card={{
                                            id: d["id"],
                                            url: d["ticket"],
                                            maps: d["maps"],
                                            image: `https://kompass.events/kompass_events/upload/new/events/${d["image"]["event_image"]}`,
                                            title: `${d["title"]} (${d["subtitle"]})`,
                                            address: `${d["adress"]}`,
                                            calendar: `${d["date"]} - ${d["time"]}`
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </Module>
        </>
    )
}
const Uberuns = () => {
    return (

        <>
            <div className="banner" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/new/banner-inner2.jpg)` }}>
                <div className="container">
                    <div className="banner-container">
                        <div className="banner-title"> {BannerData.filter((d) => { return (d["id"] === 1 && d["lang"] === Language()) })[0]["title"]}</div>
                    </div>
                </div>
            </div>
            {
                (About || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                    return (
                        <>
                            {
                                d["data"].filter((f) => { return (f["section"] === "ceo") }).map((i) => {
                                    return (
                                        <Module module="section" >
                                            <div className='row'>
                                                <div className="col-xl-6 col-lg-6 col-12 mb-4">
                                                    <div className="about-image shadow-sm" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/new/aboutus/${i["image"]})` }}></div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-12 mb-4">
                                                    <div className="about-content">
                                                        <div className="about-text" dangerouslySetInnerHTML={{ __html: i["description"] }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Module>
                                    )
                                })
                            }

                            <Module module="section" section={{ image: "https://kompass.events/kompass_events/upload/kompass_events/vmi_background.jpg", classname: "is-mobile-section" }}>
                                <div className='row'>
                                    <div className="col-xl-6 col-lg-6 col-12">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-12 is-desktop-flex">
                                                <div className="mission-content">
                                                    <img src={"https://kompass.events/kompass_events/upload/new/aboutus/aboutusother.jpg"} className="img-fluid mission-image shadow-sm" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-12 is-desktop-flex">
                                                <img src={"https://kompass.events/kompass_events/upload/kompass_events/about_us_sertap.png"} className="img-fluid mission-image mb-2 shadow-sm " />
                                                <img src={"https://kompass.events/kompass_events/upload/kompass_events/about_us_sezen.png"} className="img-fluid mission-image shadow-sm" />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-12">
                                                <img src={"https://kompass.events/kompass_events/upload/new/aboutus/ismobileaboutus.jpg"} className="img-fluid mission-image shadow-sm is-mobile" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-12">
                                        <div className="mission-content">
                                            <div>
                                                {
                                                    d["data"].filter((f) => { return (f["section"] === "vision") }).map((i) => {
                                                        return (
                                                            <>
                                                                <div className="mission-title">{i["title"]}</div>
                                                                <div className="mission-text" dangerouslySetInnerHTML={{ __html: i["description"] }}></div>
                                                            </>
                                                        )
                                                    })
                                                }
                                                <br /><br /><br />
                                                {
                                                    d["data"].filter((f) => { return (f["section"] === "mision") }).map((i) => {
                                                        return (
                                                            <>
                                                                <div className="mission-title">{i["title"]}</div>
                                                                <div className="mission-text" dangerouslySetInnerHTML={{ __html: i["description"] }}></div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Module>

                        </>
                    )
                })
            }
        </>
    )
}
const Contact = () => {

    const [Alert, setAlert] = useState({ status: 0, text: "" })
    const [Gender, setGender] = useState(0)
    const [Name, setName] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")
    const [Text, setText] = useState("")
    const Control = () => {
        if (Gender === 0) { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][0]["name"] }); return false; }
        if (Name === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][1]["name"] }); return false; }
        if (Email === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][2]["name"] }); return false; }
        if (Phone === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][3]["name"] }); return false; }
        if (Text === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][4]["name"] }); return false; }
        return true
    }
    const Customer = () => {
        var respec = ""
        if (Gender === "Frau") {
            respec = "Sehr geehrte Frau " + Name;
        }
        else {
            respec = "Sehr geehrter Herr " + Name
        }
        const content = "" +
            "" + respec + "<br/>" +
            "<br/>" +
            "Ihre Nachricht ist bei uns angekommen, wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.<br/>" +
            "<br/>" +
            "Freundliche Grüsse" +
            "<br/><br/>" +
            "Kompass Events<br/>" +
            "Schaufelbergerstrasse 57 <br/>" +
            "CH-8055 Zürich<br/><br/>" +
            "info@kompass.events<br/>" +
            "+41 (0) 44 576 60 80<br/>";
        axios.post(Root({ type: "root" }), {
            params: "send-contact",
            email: Email,
            name: "Kompass Events",
            title: "Ihre Nachricht ist bei uns angekommen",
            content: content
        })
        return true;

    }
    const Company = () => {
        const content = "" +
            "Sie haben eine neue Nachricht. Bitte nehmen Sie Kontakt mit dem Absender auf." +
            "<br/><br/>" +
            "Anrede: " + Gender + "<br/>" +
            "Name und Vorname: " + Name + "<br/>" +
            "Telefon: " + Phone + "<br/>" +
            "E-Mail: " + Email + "<br/>" +
            "Bemerkungen: " + Text + "<br/>";



        axios.post(Root({ type: "root" }), {
            params: "send-contact",
            email: "info@kompass.events",
            name: Name,
            title: "Neue Nachricht",
            content: content
        })
        return true;
    }
    const Send = () => {
        if (Control()) {
            if (Customer()) {
                if (Company()) {
                    setAlert({ status: 1, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 3) })[0]["data"][0]["name"] });
                    setGender(0)
                    setName("")
                    setEmail("")
                    setPhone("")
                    setText("")
                }
            }
        }
    }
    useEffect(() => {
        if (Alert.status) {
            setTimeout(() => {
                setAlert({ status: 0, text: "" })
            }, 4000);
        }
    }, [Alert.status])
    return (
        <>
            <div className="banner" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/new/banner-inner2.jpg)` }}>
                <div className="container">
                    <div className="banner-container">
                        <div className="banner-title"> {BannerData.filter((d) => { return (d["id"] === 3 && d["lang"] === Language()) })[0]["title"]}</div>
                    </div>
                </div>
            </div>
            <Module module="section" >

                <div className="row d-flex justify-content-center mb-1">
                    {
                        (ContactForm || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 1) }).map((d) => {
                            return (
                                (d["contact"] || []).map((d) => {
                                    return (
                                        <div className="col-lg-4 mb-3">
                                            <div className="card card-body shadow-sm" >
                                                <div className="about-us-title">{d["title"]}</div>
                                                <div className="about-us-text">{d["value"]}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        })
                    }
                    <div className="col-12 mb-4 mt-4">
                        <div className="alert-danger" hidden={Alert.status === 2 ? false : true}>
                            {Alert.text}
                        </div>
                        <div className="alert-success" hidden={Alert.status === 1 ? false : true}>
                            {Alert.text}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12">
                        <div className="card">
                            <div className="card-body contact-content">
                                <div className="row">

                                    {
                                        (ContactForm || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 1) }).map((d) => {
                                            return (
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-title">{d["form"][0]["placeholder"]} *</label>
                                                        <select className="form-select contact-form h-50px" onChange={(e) => { setGender(e.target.value) }}>
                                                            <option value={0}></option>
                                                            {d["form"][0]["subs"].map((f) => { return (<option value={f}>{f}</option>) })}
                                                        </select>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        (ContactForm || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 1) }).map((d) => {
                                            return (
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-title">{d["form"][1]["placeholder"]}  *</label>
                                                        <input className="form-control contact-form h-50px" value={Name} onChange={(e) => { setName(e.target.value) }} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        (ContactForm || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 1) }).map((d) => {
                                            return (
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-title">{d["form"][2]["placeholder"]} *</label>
                                                        <input className="form-control contact-form h-50px" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        (ContactForm || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 1) }).map((d) => {
                                            return (
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-title">{d["form"][3]["placeholder"]} *</label>
                                                        <input className="form-control contact-form h-50px" value={Phone} onChange={(e) => { setPhone(e.target.value) }} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        (ContactForm || []).filter((d) => { return (d["lang"] === Language() && d["id"] === 1) }).map((d) => {
                                            return (
                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-title">{d["form"][4]["placeholder"]} *</label>
                                                        <textarea className="form-control contact-form h-100px" value={Text} onChange={(e) => { setText(e.target.value) }} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }


                                    <div className="col-lg-12">
                                        <button className="btn btn-action-item w-100 h-50px mt-2" onClick={() => { Send() }}>
                                            {Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 5) })[0]["button"]}
                                        </button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12 d-lg-block d-sm-none">
                        <div className="contact-image contact-content shadow-sm" style={{ backgroundImage: `url(https://kompassgroup.ch/assets/img/contact.jpg)` }}></div>
                    </div>
                </div>
            </Module>
        </>
    )
}
const Detail = ({ id = "" }) => {

    return (
        <>
            {
                (Events || []).filter((d) => { return (d["id"] == id && d["lang"] === Language()) }).map((d) => {
                    return (
                        <>
                            <Module module="banner"
                                banner={{
                                    url: d["ticket"],
                                    date: `${d["date"]}T${d["time"]}`,
                                    image: `${d["image"]["poster_image"]}`,
                                    maps: d["maps"],
                                    title: `${d["title"]}`,
                                    subtitle: `${d["subtitle"]}`,
                                    address: `${d["adress"]}`,
                                    calendar: `${d["date"]} - ${d["time"]}`,
                                    response: () => { document.getElementById("tags").scrollIntoView({ behavior: 'smooth' }) }
                                }}
                            />
                            <Module module="section" >
                                <div className='row'>
                                    <div className="col-xl-6 col-lg-6 col-12 mb-4">
                                        <div className="event-info-image event-info-content" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/new/events/${d["image"]["biografy_image"]})` }}></div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-12 mb-4">
                                        <div className="event-info-content">
                                            <div>
                                                <div className="event-info-title">{d["title"]}</div>
                                                {id !== "klatscher-2024" && <div className="event-info-subtitle">{d["subtitle"]}</div>}
                                                <div className="event-info-text" dangerouslySetInnerHTML={{ __html: d["description"] }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Module>

                            {
                                d["tags"]["data"].length > 0 &&
                                <Module module="section" section={{ title: d["tags"]["section_title"] }}>
                                    <div className='row' id="tags">
                                        <div className="col-xl-6 col-lg-6 col-md-21 mb-4">
                                            <div className="event-info-image shadow-sm" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/new/events/${d["tags"]["section_image"]})` }}></div>
                                        </div>
                                        < div className="col-xl-6 col-lg-6 col-12 mb-4">
                                            <div className="event-info-content">
                                                <div className="row">
                                                    {
                                                        (d["tags"]["data"] || []).map((t) => {
                                                            return (
                                                                <div className={` ${t["name"].length > 30 ? "col-lg-12" : "col-lg-6"}   col-12 mb-2`}>
                                                                    <div className="card shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="event-tag-title">{t["jobs"]}</div>
                                                                            <div className="event-tag-text">{t["name"]}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Module>
                            }
                            {
                                d["program"]["data"].length > 0 &&
                                <Module module="section" section={{ title: d["program"]["section_title"] }}>
                                    <div className='row' id="tags">
                                        {
                                            (d["program"]["data"] || []).filter((f) => { return (f["section"] === 1) }).map((p) => {
                                                return (
                                                    <div className="col-xl-6 col-lg-6 col-12 mb-4">
                                                        <div className="card shadow-sm event-program-content">
                                                            <div className="card-body">
                                                                <div className="event-program-section">{p["stage"]}</div>
                                                                <div className="row">
                                                                    {
                                                                        (p["composer"] || []).map((c) => {
                                                                            return (
                                                                                <div className={`col-lg-12 col-12 mb-4`}>

                                                                                    <div className="event-program-title">{c["name"]} {c["date"]}</div>
                                                                                    <div className="event-program-subtitle">{c["album"]}</div>
                                                                                    <div className="event-program-list">
                                                                                        {
                                                                                            (c["lists"] || []).map((l) => {
                                                                                                return (
                                                                                                    <div className="event-program-list-item">
                                                                                                        <i class="fa-solid fa-circle-dot me-2"></i>
                                                                                                        {l["name"]}
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            (d["program"]["data"] || []).filter((f) => { return (f["section"] === 2) }).map((p) => {
                                                return (
                                                    <div className="col-xl-6 col-lg-6 col-12 mb-4">
                                                        <div className="card shadow-sm event-program-content">
                                                            <div className="card-body">
                                                                <div className="event-program-section">{p["stage"]}</div>
                                                                <div className="row">
                                                                    {
                                                                        (p["composer"] || []).map((c) => {
                                                                            return (
                                                                                <div className={`col-lg-12 col-12 mb-4`}>

                                                                                    <div className="event-program-title">{c["name"]} {c["date"]}</div>
                                                                                    <div className="event-program-subtitle">{c["album"]}</div>
                                                                                    <div className="event-program-list">
                                                                                        {
                                                                                            (c["lists"] || []).map((l) => {
                                                                                                return (
                                                                                                    <div className="event-program-list-item">
                                                                                                        <i class="fa-solid fa-circle-dot me-2"></i>
                                                                                                        {l["name"]}
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }


                                    </div >
                                </Module>
                            }
                            {
                                d["video"]["data"].length > 0 &&
                                <Module module="section" section={{ title: d["video"]["section_title"], classname: "bg-gray" }}>
                                    <div className="row">
                                        {
                                            (d["video"]["data"] || []).map((t) => {
                                                return (
                                                    <div className="col-lg-6 col-md-6 col-12 mb-4">
                                                        <Module module="video" video={{ data: t }} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Module>
                            }
                            {
                                d["galery"]["data"].length > 0 &&
                                <Module module="section" section={{ title: d["galery"]["section_title"] }}>
                                    <div className="row">
                                        {
                                            (d["galery"]["data"] || []).map((t, x) => {
                                                return (
                                                    <div className="col-lg-4 col-md-6 col-12 mb-3">
                                                        <Module module="image" image={{ data: t, corporate: d["galery"]["corporate"][x] }} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Module>
                            }
                            <Module module="section" section={{ title: d["sponsors"]["section_title"] }}>
                                <div className="row d-flex justify-content-center aling-items-center">
                                    {
                                        d["sponsors"]["data"].length > 0 &&
                                        <>
                                            {
                                                (d["sponsors"]["data"] || []).map((t, x) => {
                                                    return (
                                                        <div className="col-lg-2 col-md-6 col-12 mb-3">
                                                            <a href={t.url} target="_blank" className="d-flex justify-content-center aling-items-center">
                                                                <img src={t.image} target="_blank" style={{ width: "80%", height: "auto" }} />
                                                            </a>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </>
                                    }
                                </div>
                            </Module>


                        </>
                    )
                })
            }


        </>
    )
}
const Accredited = () => {
    const fileRef = useRef()
    const [Alert, setAlert] = useState({ status: 0, text: "" })

    const [Gender, setGender] = useState(0)
    const [Name, setName] = useState("")
    const [Jobs, setJobs] = useState("")
    const [Build, setBuild] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")
    const [PressCard, setPressCard] = useState("")
    const [BroadcastingName, setBroadcastingName] = useState("")
    const [BroadcastingType, setBroadcastingType] = useState("")
    const [ActivitySelect, setActivitySelect] = useState("")
    const [ActivityJoin, setActivityJoin] = useState("")
    const [OtherText, setOtherText] = useState("")
    const [Document, setDocument] = useState("")
    const [DocumentInfo, setDocumentInfo] = useState("")

    const Control = () => {
        if (Gender === 0) { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 1) })[0]["data"][0]["name"] }); return false; }
        if (Name === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][1]["name"] }); return false; }
        if (Jobs === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][2]["name"] }); return false; }
        if (Build === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][3]["name"] }); return false; }
        if (Email === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][4]["name"] }); return false; }
        if (Phone === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][5]["name"] }); return false; }
        if (PressCard === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][6]["name"] }); return false; }
        if (BroadcastingName === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][7]["name"] }); return false; }
        if (BroadcastingType === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][8]["name"] }); return false; }
        if (ActivitySelect === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][9]["name"] }); return false; }
        if (ActivityJoin === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][10]["name"] }); return false; }
        if (OtherText === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][11]["name"] }); return false; }
        if (Document === "") { setAlert({ status: 2, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 2) })[0]["data"][12]["name"] }); return false; }
        return true
    }
    const Upload = async (e) => {
        e.preventDefault();
        const [file] = e.target.files;
        const data = new FormData();
        if (file) {
            data.append('files', file);
            var result = (await axios.post(Root({ type: "upload" }), data)).data
            setDocument(`https://kompass.events/kompass_events/${result.slice(2, 500)}`)
            setDocumentInfo(file.name)
            console.log(file)
        }
        if (fileRef.current) {
            fileRef.current.value = "";
        }
    }
    const Customer = () => {
        var respec = ""
        if (Gender === "Frau") {
            respec = "Sehr geehrte Frau " + Name;
        }
        else {
            respec = "Sehr geehrter Herr " + Name
        }
        const content = "" +
            "" + respec + "<br/>" +
            "<br/>" +
            "Ihre Nachricht ist bei uns angekommen, wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.<br/>" +
            "<br/>" +
            "Freundliche Grüsse" +
            "<br/><br/>" +
            "Kompass Events<br/>" +
            "Schaufelbergerstrasse 57 <br/>" +
            "CH-8055 Zürich<br/><br/>" +
            "info@kompass.events<br/>" +
            "+41 (0) 44 576 60 80<br/>";
        axios.post(Root({ type: "root" }), {
            params: "send-contact",
            email: Email,
            name: "Kompass Events",
            title: "Ihre Nachricht ist bei uns angekommen",
            content: content
        })
        return true;

    }
    const Company = () => {
        const content = "" +
            "Sie haben eine neue Nachricht. Bitte nehmen Sie Kontakt mit dem Absender auf." +
            "<br/><br/>" +
            "Anrede: " + Gender + "<br/>" +
            "Name und Vorname: " + Name + "<br/>" +
            "Berufsbezeichnung: " + Jobs + "<br/>" +
            "Institution/Organisation: " + Company + "<br/>" +
            "E-Mail: " + Email + "<br/>" +
            "Telefon: " + Phone + "<br/>" +
            "Presseausweisnummer: " + PressCard + "<br/>" +
            "Name des Publikationsorgans: " + BroadcastingName + "<br/>" +
            "Art des Publikationsorgans : " + BroadcastingType + "<br/>" +
            "Spezifisches Interesse an der Veranstaltung  : " + ActivitySelect + "<br/>" +
            "Frühere Teilnahmen/Veröffentlichungen  : " + ActivityJoin + "<br/>" +
            "Zusätzliche Notizen  : " + OtherText + "<br/>" +
            "Dokument:  <a href=" + Document + ">Dokument</a><br/>" +
            "<br/>";
        axios.post(Root({ type: "root" }), {
            params: "send-contact",
            email: "info@kompass.events",
            name: Name,
            title: "Neue Nachricht",
            content: content
        })
        return true;
    }
    const Send = () => {
        if (Control()) {
            if (Customer()) {
                if (Company()) {
                    setAlert({ status: 1, text: AlertData.filter((d) => { return (d["lang"] === Language() && d["id"] === 3) })[0]["data"][0]["name"] });
                    setDocumentInfo("")
                    setGender(0)
                    setName("")
                    setJobs("")
                    setBuild("")
                    setEmail("")
                    setPhone("")
                    setPressCard("")
                    setBroadcastingName("")
                    setBroadcastingType("")
                    setActivitySelect("")
                    setActivityJoin("")
                    setOtherText("")
                    setDocument("")
                    setDocumentInfo("")
                }
            }
        }
    }

    useEffect(() => {
        if (Alert.status) {
            setTimeout(() => {
                setAlert({ status: 0, text: "" })
            }, 4000);
        }
    }, [Alert.status])
    return (
        <>
            <div className="banner" style={{ backgroundImage: `url(https://kompass.events/kompass_events/upload/new/banner-inner2.jpg)` }}>
                <div className="container">
                    <div className="banner-container">
                        <div className="banner-title"> {BannerData.filter((d) => { return (d["id"] === 4 && d["lang"] === Language()) })[0]["title"]}</div>
                    </div>
                </div>
            </div>
            <Module module="section" >
                <div className="col-xl-12 col-lg-6 col-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="accredited-title"> {Section.filter((d) => { return (d["id"] === 3 && d["lang"] === Language()) })[0]["title"]}</div>
                            <div className="accredited-text"> {Section.filter((d) => { return (d["id"] === 3 && d["lang"] === Language()) })[0]["text"]}</div>
                        </div>
                    </div>
                </div>

                <div className="row mobile-resorce">
                    <div className="col-lg-12 mt-2">
                        <div className="alert-danger" hidden={Alert.status === 2 ? false : true}>
                            {Alert.text}
                        </div>
                        <div className="alert-success" hidden={Alert.status === 1 ? false : true}>
                            {Alert.text}
                        </div>

                        <div className="card border-radius-12 mb-3" >
                            <div className="card-header">
                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 1) }).map((s) => { return (s["title"]) }))
                                })}
                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-lg-12 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 1) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 1 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <select className="form-select contact-form h-50px" onChange={(e) => { setGender(e.target.value) }}>
                                                <option value={0}></option>

                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 1) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => {
                                                                return (r["id"] === 1 && <>{
                                                                    (r["subs"] || []).map((k) => {
                                                                        return (<option value={k}>{k}</option>)
                                                                    })
                                                                } *</>)
                                                            })
                                                        )
                                                    }))
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 1) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 2 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <input className="form-control contact-form h-50px" value={Name} onChange={(e) => { setName(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 1) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 3 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <input className="form-control contact-form h-50px" value={Jobs} onChange={(e) => { setJobs(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 1) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 4 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <input className="form-control contact-form h-50px" value={Build} onChange={(e) => { setBuild(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 1) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 5 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <input className="form-control contact-form h-50px" value={Phone} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 1) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 6 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <input className="form-control contact-form h-50px" value={Phone} onChange={(e) => { setPhone(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="card border-radius-12 mt-3">
                            <div className="card-header">
                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 2) }).map((s) => { return (s["title"]) }))
                                })}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 2) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 1 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <input className="form-control contact-form h-50px" value={PressCard} onChange={(e) => { setPressCard(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 2) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 2 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <input className="form-control contact-form h-50px" value={BroadcastingName} onChange={(e) => { setBroadcastingName(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 2) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 3 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <input className="form-control contact-form h-50px" value={BroadcastingType} onChange={(e) => { setBroadcastingType(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card border-radius-12 mt-3">
                            <div className="card-header">
                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 3) }).map((s) => { return (s["title"]) }))
                                })}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 3) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 1 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <input className="form-control contact-form h-50px" value={ActivitySelect} onChange={(e) => { setActivitySelect(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 3) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 2 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <input className="form-control contact-form h-50px" value={ActivityJoin} onChange={(e) => { setActivityJoin(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card border-radius-12 mt-3">
                            <div className="card-header">
                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 4) }).map((s) => { return (s["title"]) }))
                                })}
                            </div>
                            <div className="card-body">
                                <div className="akredite-upload" onClick={() => { fileRef.current.click() }}>
                                    {
                                        DocumentInfo === "" ?
                                            <div className="d-flex">
                                                <div className="w-50px me-2 d-flex align-items-center">
                                                    <i class="fa-solid fa-cloud-arrow-up fs-1 text-gray"></i>
                                                </div>
                                                <div className="w-100">
                                                    {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                        return ((d["data"] || []).filter((d) => { return (d["id"] === 4) }).map((s) => {
                                                            return (
                                                                (s["data"] || []).map((r) => { return (r["id"] === 1 && <><div>{r["placeholder"]}</div></>) })
                                                            )
                                                        }))
                                                    })}
                                                </div>
                                            </div>
                                            :
                                            <div className="d-flex justify-content-center">
                                                <div className="card w-100 shadow-sm">
                                                    <div className="card-body">
                                                        {DocumentInfo}
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    <input ref={fileRef} type="file" onChange={Upload} hidden />
                                </div>
                            </div>
                        </div>

                        <div className="card border-radius-12 mt-3">
                            <div className="card-header">
                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 5) }).map((s) => { return (s["title"]) }))
                                })}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label className="form-title">
                                                {(AkretidationData || []).filter((d) => { return (d["lang"] === Language()) }).map((d) => {
                                                    return ((d["data"] || []).filter((d) => { return (d["id"] === 5) }).map((s) => {
                                                        return (
                                                            (s["data"] || []).map((r) => { return (r["id"] === 1 && <>{r["placeholder"]} *</>) })
                                                        )
                                                    }))
                                                })}
                                            </label>
                                            <textarea className="form-control contact-form h-100px" value={OtherText} onChange={(e) => { setOtherText(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-2">
                            <button className="btn btn-action-item w-100 h-50px" onClick={() => { Send() }}>
                                {Button.filter((d) => { return (d["lang"] === Language() && d["id"] === 5) })[0]["button"]}
                            </button>
                        </div>
                    </div>
                </div>
            </Module>
        </>
    )
}

export default function Page() {
    const { page, type, name, id } = useParams()
    switch (page) {
        case "event": {
            switch (type) {
                case "detail": return <Detail id={id} />;
                default: return <Event />;
            }
        }
        case "faq": return <Faq />;
        case "uberuns": return <Uberuns />;
        case "kontakt": return <Contact />;
        case "akkreditierung": return <Accredited />;
        default: return <Home />
    }
}
